import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import logo from "../../images/mb-m-500-white.png";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import AdobeImage from "images/adobe.svg";
import ReactImage from "images/react-brands.svg";
import NodeImage from "images/node-brands.svg";
import MetaImage from "images/meta-1.svg";
import GoogleImage from "images/Google.svg";
import AWSIconImage from "images/aws-brands.svg";
import DesignImage from "images/diagram-project-solid.svg";
import ProjectImage from "images/chart-gantt-solid.svg";
import CRMImage from "images/sitemap-solid.svg";
import EmailImage from "images/envelope-solid.svg";
import DatabaseImage from "images/database-solid.svg";
import RecordImage from "images/record-vinyl-solid.svg";
import TwitterImage from "images/twitter-brands.svg";
import XeroImage from "images/dollar-sign-solid.svg";
import ZohoImage from "images/zoho.svg";
import HubspotImage from "images/hubspot-brands.svg";
import PGImage from "images/postgresql.svg";
import MySQLImage from "images/mysql.svg";
import JIRAImage from "images/jira-brands.svg";

const Container = tw.div`relative bg-primary-900 -mx-8 px-8 text-gray-100`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center items-stretch flex-row flex-wrap justify-center max-w-screen-lg mx-auto py-10 md:py-10`}
`;
//const Subheading = tw(SubheadingBase)`mb-4 text-gray-100`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center text-gray-300`;
const LogoImg = tw.img`w-8`;
const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`w-1/3 lg:w-1/6`}
`;

const Card = styled.div`
  ${tw`flex flex-col items-center text-center h-full mx-2 px-2 py-2`}
  .imageContainer {
    ${tw`bg-gray-100 text-center rounded-full p-2 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer 
    ${tw`mt-2`}
  }

  .title {
    ${tw`tracking-wider w-2 leading-none text-xs font-bold text-gray-300`}
  }

  .description {
    ${tw`mt-2 font-normal text-gray-400 leading-snug`}
  }
`;

export default ({
  cards = null,
  heading = "Application expertise",
  // subheading = "Metabetta",
  description = "We cover the full spectrum of applications that drive a modern technology business, but the below show some of our specialty areas of expertise."
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
        imageSrc: AdobeImage,
        title: "Adobe Creative Cloud",
    },
    {
        imageSrc: AWSIconImage,
        title: "AWS Amplify",
    },
    {
        imageSrc: AWSIconImage,
        title: "Amazon API Gateway",
    },
    {
        imageSrc: AWSIconImage,
        title: "Amazon Athena",
    },
    {
        imageSrc: AWSIconImage,
        title: "Amazon Aurora",
    },
    {
        imageSrc: AWSIconImage,
        title: "Amazon Cloudfront",
    },
    {
        imageSrc: AWSIconImage,
        title: "Amazon Connect",
    },
    {
        imageSrc: RecordImage,
        title: "CWR",
    },
    {
        imageSrc: RecordImage,
        title: "DDEX BWARM",
    },
    {
        imageSrc: RecordImage,
        title: "DDEX ERN",
    },
    {
        imageSrc: AWSIconImage,
        title: "Amazon DocumentDB",
    },
    {
        imageSrc: AWSIconImage,
        title: "Amazon DynamoDB",
    },
    {
        imageSrc: AWSIconImage,
        title: "Amazon EC2",
    },
    {
        imageSrc: MetaImage,
        title: "Facebook Ads Manager",
    },
    {
        imageSrc: GoogleImage,
        title: "Google Analytics",
    },
    {
        imageSrc: GoogleImage,
        title: "Google Search Console",
    },
    {
      imageSrc: GoogleImage,
      title: "Google Sheets",
    },
    {
        imageSrc: GoogleImage,
        title: "Google Workspace",
    },
    {
        imageSrc: HubspotImage,
        title: "Hubspot",
    },
    {
        imageSrc: JIRAImage,
        title: "JIRA",
    },
    {
        imageSrc: AWSIconImage,
        title: "AWS Lambda",
    },
    {
        imageSrc: EmailImage,
        title: "MailChimp",
    },
    {
        imageSrc: MetaImage,
        title: "Meta Business Suite",
    },
    {
        imageSrc: MySQLImage,
        title: "MySQL",
    },
    {
      imageSrc: NodeImage,
      title: "Node",
    },
    {
        imageSrc: AWSIconImage,
        title: "Amazon Polly",
    },
    {
        imageSrc: PGImage,
        title: "PostgreSQL",
    },
    {
        imageSrc: AWSIconImage,
        title: "Amazon RDS",
    },
    {
        imageSrc: ReactImage,
        title: "React",
    },
    {
        imageSrc: EmailImage,
        title: "SendInBlue",
    },
    {
        imageSrc: TwitterImage,
        title: "TweetDeck",
    },
    {
        imageSrc: TwitterImage,
        title: "Twitter For Business",
    },
    {
        imageSrc: AWSIconImage,
        title: "Amazon VPC",
    },
    {
        imageSrc: XeroImage,
        title: "Xero",
    },
    {
        imageSrc: ZohoImage,
        title: "Zoho Analytics",
    },
    {
        imageSrc: ZohoImage,
        title: "Zoho CRM",
    }
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {/* {subheading && <Subheading className="mblogo">{subheading}</Subheading>} */}
        <LogoImg src={logo} />
        <Heading>{heading}</Heading>        
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || ""}</span>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
