import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import logo from "../../images/mb-m-500-white.png";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";
import AWSIconImage from "images/aws-brands.svg";
import AudioIconImage from "images/file-audio-solid.svg";
import ChessImage from "images/chess-solid.svg";
import SitemapImage from "images/sitemap-solid.svg";
import NodesImage from "images/circle-nodes-solid.svg";
import DatabaseImage from "images/database-solid.svg";
import RecordImage from "images/record-vinyl-solid.svg";

const Container = tw.div`relative bg-primary-900 -mx-8 px-8 text-gray-100`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
//const Subheading = tw(SubheadingBase)`mb-4 text-gray-100`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center text-gray-300`;
const LogoImg = tw.img`w-8`;
const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-xs`}
`;

const Card = styled.div`
  ${tw`flex flex-col items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`bg-gray-100 text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`mt-6`}
  }

  .title {
    ${tw`tracking-wider font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-2 font-normal text-gray-400 leading-snug`}
  }
`;

export default ({
  cards = null,
  heading = "Our Services",
  // subheading = "Metabetta",
  description = "Metabetta offers 360 degree consultancy across Data Science, Software Development, Project and Product Management. Speak to the person that does the engineering and get insight borne of experience."
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: ChessImage,
      title: "Strategy, Product & Project Management",
      description: "From agency side to client side, Metabetta have seen all sides of product and project management, and have extensive experience providing detailed advice. We can build bespoke data models, analyse any of your current data or find you reliable new insights to expand your opportunities."
    },
    { imageSrc: NodesImage, 
      title: "Enterprise Architecture", 
      description: "We specialise in Enterprise architecture and can build bespoke systems that scale. From growing and optimising consumer sites performing millions of dollars in sales per week to data and physical supply chain integration, Metabetta’s cross-disciplinary experience provides unique insights into how to make your product move faster and perform more reliably."
    },
    { imageSrc: DatabaseImage, title: "Data & Software Engineering",
      description: "With extensive insights gained from high-performance eCommerce websites and the largest non-streaming music data website, Metabetta can review and build bespoke software and data solutions that are designed to scale. We can do bespoke development ourselves, manage outsourced teams or find you the right 3rd party solutions."},
    { imageSrc: AWSIconImage, 
      title: "AWS Architecture & DevOps",
      description: "Utilising 10 years of AWS experience across various businesses - everything from large scale businesses to multiple startups, Metabetta have experience in designing, building and optimising on AWS across the full stack."
    },
    { imageSrc: AudioIconImage, title: "Music Industry Consulting",
      description: "With detailed experience in music industry processes and data standards, and access to the largest supply of data from across the music industry, Metabetta can offer insight born of experience when it comes to processing and organising solutions at scale. A DDEX member with hands-on experience building big data systems from scratch, we understand how systems fit together and what the bug bears are for the industry."
    },
    { imageSrc: RecordImage, 
      title: "Metabetta L.E.T. & the Metabetta database",
      description: "Our proprietary Metabetta L.E.T. (Load, Extract, Transform) software utilises an innovative hybrid Document and Relational database to ensure our clients can access the files they've been sent without ever having to leave their comfort of their database."
    }
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container id="services">
      
      <ThreeColumnContainer>
        {/* {subheading && <Subheading className="mblogo">{subheading}</Subheading>} */}
        <LogoImg src={logo} />
        <Heading>{heading}</Heading>        
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
        
      </ThreeColumnContainer>

    </Container>
  );
};
