import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Hero from "components/hero/MBHero.js";
//import Features from "components/features/ThreeColWithSideImage.js";
import Features from "components/features/MBFeatures.js";
import Applications from "components/features/MBApplications.js";
import OurSoftware from "components/features/OurSoftware.js";
import OurEComSoftware from "components/features/OurEComSoftware.js";
import MainFeature from "components/features/MBMainFeatures.js";
//import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
//import Testimonial from "components/testimonials/SimplePrimaryBackground.js";
//import FAQ from "components/faqs/TwoColumnPrimaryBackground.js";
import ContactUsForm from "components/forms/MBContact.js";
// import Blog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
import Footer from "components/footers/MBFooter.js";
import AboutUs from "components/features/MBAbout.js";
import TooHardBasket from "components/features/MBMainFeatures.js";
import Publishing from "images/publishing.png";
import Streaming from "images/streaming.png";
import Architecture from "images/architecture.png";
import Forecasting from "images/forecasting.png";
import Roadmap from "images/roadmap.png";
//import Microphone from "images/microphone.png";
import DataFlow3 from "images/metabetta_dataflow3.png";
import DataLakeCloud from "images/metabetta_data_lake_cloud.png";
import DataLake from "images/metabetta_data_lake.png";
import Basket from "images/basket.png";

export default () => {
  return (
    <AnimationRevealPage disabled>
      <Hero />
      {/* Our Services*/}
      <Features 
      />
      {/* <Pricing /> */}
      {/* Featured Software Solutions */}
      {/* About Us */}
      <AboutUs 
        imageSrc={DataLake}
      />
      <MainFeature 
        id="metabettatoohard"
        subheading = "Making your life easier"
        heading = "Let us sort your Too Hard Basket."
        description = "From privacy data mapping to developing financial modelling, we're here to make your life easier and take on the jobs you don't have the capacity to do internally. With a wealth of digital experience at both enterprise and start-up scale, we've been hands on with all aspects of tech and compliance."
        primaryButtonText = "Contact Us"
        primaryButtonUrl = "/#contact"
        imageSrc = {Basket}
        textOnLeft = {true}
      />
      <OurEComSoftware 
       />
      <MainFeature 
        id="metabettadata"
        subheading="Data based solutions"
        heading="Forecasting &amp; Analysis"
        imageSrc={Roadmap}
        buttonRounded={false}
        textOnLeft={false}
        description = "We can take your data, customer analytics and past performance and develop forecasting models, roadmaps and means to get from now to where you need to be. We custom build to suit every client, so we're making sure every solution is tailored to you, not just applying broad generalities."
        primaryButtonText = "Contact Us"
        primaryButtonUrl = "#contact"
      />
      <MainFeature 
        id="metabettadataarch"
        subheading="Best in class methods and recommendations"
        heading="Enterprise Architecture and Mapping"
        imageSrc={Architecture}
        buttonRounded={false}
        textOnLeft={true}
        description = "With over 20 years of experience at planning, mapping, documenting and delivering slutions at Enterprise Scale, we can help analyse, improve, redesign or simply get a sense check on your systems."
        primaryButtonText = "Contact Us"
        primaryButtonUrl = "#contact"
      />
      <OurSoftware />
      <MainFeature 
        id="metabettamusicdata"
        subheading="Finding &amp; forecasting music data"
        heading="Metabetta Database"
        imageSrc={DataFlow3}
        buttonRounded={false}
        textOnLeft={true}
        description = "We're constantly analysing, importing and mapping all offical industry data sources covering over 90M recordings, 350M streaming usages and 25M musical works. We can help you map your data, imporve your systems and connect you with experts who can help you claim your missing royalties. We specialise in bespoke end-to-end data mapping and entity resolution."
        primaryButtonText = "Contact Us"
        primaryButtonUrl = "#contact"
      />
      <MainFeature 
        id="metabettadatalet"
        subheading="An innovative hybrid relational and document database platform."
        heading="Metabetta L.E.T."
        imageSrc={DataLakeCloud}
        buttonRounded={false}
        textOnLeft={false}
        description = "Metabetta L.E.T. allows you to load documents into your database as structured JSON, and then utilise the power of relational data to query at scale. No more waiting for document imports or requiring costly re-loads of data, Metabetta L.E.T. is truly the best of both worlds. Your database and your data lake can now be navigated at scale."
        primaryButtonText = "Contact Us"
        primaryButtonUrl = "#contact"
      />
      <MainFeature 
        id="metabettadataddex"
        subheading="For Music Streaming Services and Metadata Companies"
        heading="Metabetta L.E.T. - DDEX ERN"
        description = "Metabetta L.E.T. DDEX ERN is the fastest and most efficient way to import data from DDEX ERN XML files. Need audio transcoding integrated? No problem. Image resizing on demand? Simple. Extracting credits, deduplication, mapping to streaming service API's? We have re-invented handling of XML data to give you the ultimate in speed and flexibility."
        primaryButtonText = "Contact Us"
        primaryButtonUrl = "#contact"
        imageSrc={Streaming}
        buttonRounded={false}
      />
      <MainFeature 
        id="metabettadatacwr"
        subheading="For Publishers and Music Licensing Companies"
        heading="Metabetta L.E.T. - CWR"
        description = "Metabetta L.E.T. CWR can import, extract and transform your CWR files into your own data model, or any format you see fit. It can then analyse the data and export CWR for output, allowing you to develop your core data model independent of CWR constraints."
        primaryButtonText = "Contact Us"
        primaryButtonUrl = "#contact"
        imageSrc={Publishing}
        buttonRounded={false}
        textOnLeft={false}
      />
      <Applications />
      {/* <Testimonial /> */}
      {/* <FAQ /> */}
      {/* <Blog /> */}
      <ContactUsForm />
      <Footer />
      </ AnimationRevealPage>
  );
}
